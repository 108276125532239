.container-top {
  padding: 10px 50px;
  position: fixed;
  top: 0px;
  z-index: 1;
  background-color: #023956;
  color: #ffffff;
  border-top: 4px solid #f89031;
}

.container-middle {
  z-index: 2;
  margin-top: 60px;
}

.container-bottom {
  background-color: #ffffff;
  background-position: center;
  background-size: contain;
  padding: 20px;
}

.gap-10 {
  display: flex;
  gap: 10px;
}

.nav-item {
  background-color: #ebebeb;
}

.nav-link {
  color: #000000;
}

.nav-link:hover {
  color: #f89031;
}

.active {
  color: #f89031;
  border-bottom: 2px solid #f89031;
}

.input-search {
  border: 1px solid #f0f0f0;
  outline: none;
  padding: 10px;
}

.btn-solid {
  background-color: #f89031;
  color: #ffffff;
  border: none;
  padding: 10px 30px;
  font-size: 14px;
  font-weight: bold;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0.1)
  );
}

.btn-solid:hover {
  background-color: #df7d22;
}

.text-ns {
  color: #df7d22;
}

.tag {
  border-bottom: 2px solid #f89031;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  max-width: 60%;
}

.paper {
  position: relative;
  display: flex;
  box-shadow: 0px 1px 5px rgb(0 0 0 / 25%);
  padding: 10px 10px 0px 10px;
  margin: 10px 0px;
}

.icon-50 {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background-color: #df7d22;
  text-align: center;
  font-size: 40px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.link {
  font-size: 14px;
  font-weight: bold;
  color: #0e4f71;
  cursor: pointer;
}

.link:visited {
  color: #0e4f71;
}

.link:hover {
  color: #0e4f71;
}

.float-right {
  position: absolute;
  top: 20px;
  right: 10px;
}

.btn-outline {
  position: relative;
  top: 100%;
  background-color: rgb(0 0 0 / 0%);
  color: #ffffff;
  border: none;
  padding: 10px 30px;
  font-size: 14px;
  font-weight: bold;
  border: 2px solid #ffffff;
  transition: all 0.5s ease-out;
}

.btn-outline:hover {
  color: #df7d22;
  border: 2px solid #df7d22;
}

.div-details:hover > .btn-outline {
  top: 50%;
}

.fs-14 {
  font-size: 14px;
}

.bg-ns {
  background-color: #f89031;
}

.banner {
  height: 200px;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  color: #ffffff;
  padding-top: 84px;
  font-size: 36px;
  font-weight: 600;
  margin: 5px 0px;
}

.photo {
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;
}

.contact {
  background-position: center;
  text-align: center;
  background-attachment: fixed;
  margin: 5px 0px;
  background-size: cover;
}

.btn-blue {
  background-color: #1a82b5;
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0.1)
  );
  border: none;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-right: 10px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.reviews {
  position: relative;
  height: 500px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

@media (max-width: 990px) {
  .container-middle {
    margin-top: 90px;
  }
  .gap-20 {
    gap: 20px;
    margin-bottom: 20px;
  }
  .banner {
    padding-top: 48px;
    font-size: 24px;
  }
  .reviews {
    height: 600px;
    font-size: 16px;
  }
}
